import React, { Fragment } from "react";
import Helmet from "react-helmet";
import styled, { createGlobalStyle, ThemeProvider } from "styled-components";
import { normalize } from "styled-normalize";

import "typeface-inconsolata";

const theme = {
  blueLight: "#edf5fa",
  blue: "#3c7ca8",
  blueDark: "#254e69",
};

const GlobalStyles = createGlobalStyle`
  *,
  ::before,
  ::after {
    box-sizing: border-box;
  }

  ${normalize}

  html,
  body {
    height: 100%;
  }

  body {
    background-color: ${({ theme }) => theme.blueLight};
    font-family: 'Inconsolata', monospace;
  }

  #___gatsby,
  #gatsby-focus-wrapper {
    height: 100%;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  padding: 30px;

  @media screen and (min-width: 768px) {
    padding: 50px;
  }
`;

function Layout({ children }) {
  return (
    <Fragment>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <Helmet charSet="utf-8" lang="en" title="Lee Allen" />
        <Container>{children}</Container>
      </ThemeProvider>
    </Fragment>
  );
}

export default Layout;
