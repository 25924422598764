exports.onInitialClientRender = () => {
  if (process.env.NODE_ENV === "production") {
    const script = document.createElement("script");
    script.type = "text/javascript";

    const code = `
      if (typeof window.__REACT_DEVTOOLS_GLOBAL_HOOK__ === 'object') {
        Object.keys(window.__REACT_DEVTOOLS_GLOBAL_HOOK__)
          .forEach((key) => {
            const val = window.__REACT_DEVTOOLS_GLOBAL_HOOK__[key];
  
            window.__REACT_DEVTOOLS_GLOBAL_HOOK__[key] = typeof val === 'function' ? () => {} : null;
          });
      }
    `;

    try {
      script.appendChild(document.createTextNode(code));
      document.body.appendChild(script);
    } catch (err) {
      script.text = code;
      document.body.appendChild(script);
    }
  }
};
